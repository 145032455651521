import CLRequest from '../request'


export function getNewsList(params) {
  return CLRequest.get({ 
    url: "/api/news/newsList",
    params
  })
}
export function getNewsDetail(id,status) {
  return CLRequest.get({ 
    url: `/api/news/getNewsDetail/${id}/${status}`,
  })
}

export function getNewsSuggest(params) {
  return CLRequest.get({ 
    url: "/api/news/newsTopList",
    params
  })
}
export function getNewsRecommend() {
  return CLRequest.get({ 
    url: "/api/home/recommendNews",
  })
}


