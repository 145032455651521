import { param } from 'jquery'
import CLRequest from '../request'


export function serveMain(params) {
  return CLRequest.get({ 
    url: "/api/common/allList",
    params
  })
}

export function addWordOrderApi(data) {
  return CLRequest.post({ 
    url: "/api/itr/addWorkOrder",
    data
  })
}

export function wordOrderListApi(data) {
  return CLRequest.post({
    url: "/api/itr/processList",
    data
  })
}

export function wordOrderDetailApi(params) {
  return CLRequest.get({
    url: "/api/itr/detail/" + params.id,
  })
}

export function companyListApi(data) {
  return CLRequest.post({ 
    url: "/api/itr/companyList",
    data
  })
}