<template>
  <div class="header on1" :class="!isActive ? 'white' : ''">
    <div class="con">
      <div class="h_left">
        <a @click="switchTab('/')" class="logo">
          <img :src="formatImg(baseinfo?.logo)" alt="" />
        </a>
        <ul class="h_nav f_16">
          <template v-for="(item, index) in xlTitle" :key="item.id">
            <li v-if="item.status == 0 && item.flag == 0">

              <a v-if="item.router != '#'" @click="switchTab(item.router)" class="a">{{ item.name }}</a>
              <!-- <router-link v-if="item.router != '#'"
                           :to="item.router"
                           class="a">{{ item.name }}</router-link> -->
              <div v-else :to="item.router" class="a">{{ item.name }}</div>
            </li>
          </template>
        </ul>
      </div>
      <div class="h_right">
        <a class="ser" @click="toggleSearch()">
          <img src="@/assets/img/h_ser.png" alt="" />
        </a>
        <div class="lag">
          <div class="h f_16">
            <img src="@/assets/imgl/dllang.svg" alt="" /> {{ languageMap[locale] }}
          </div>
          <ul>
            <template v-for="item in languages" :key="item.label">
              <li>
                <a :href="item.url" class="f_16" :target="item.is_blank ? '_blank' : ''" @click="switchLang($event, item)">{{
                  item.label
                }}</a>
              </li>
            </template>
          </ul>
        </div>
        <div class="btn_box f_14" v-if="!userInfo.jaka">
          <a @click="switchTab('/login')" class="btn">{{ t('login.login') }}</a>
          <div class="solid"></div>
          <a @click="switchTab('/register')" class="btn">{{ t('login.register') }}</a>
        </div>
        <div class="user_info f_16" v-else>
          <div class="avatar">
            <img src="@/assets/imgl/avatar.png" alt="" />
          </div>
          <div class="user_name">{{ userInfo.userName }}</div>

          <ul>
            <li>
              <a class="f_16" @click="switchTab('/deregister')">{{ t('login.deregister') }}</a>
            </li>
            <li><a class="f_16" @click="unLogin">{{ t('login.logout') }}</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ps_xl closexl">
      <div class="cover"></div>
      <div class="ps_inner">
        <div v-for="(item, index) in xlTitle" :key="item.id" class="add-box">
          <div v-if="item.uniqueness == '产品中心'" class="pl_navs liBox">
            <div class="pl_nav">
              <div class="type">
                <div class="title">
                  {{ xlTitle[index]?.children[0]?.name }}
                </div>
                <div class="list">
                  <template v-for="item in list_p" :key="item.id">
                    <div class="box" v-if="item.id != rightProductLineId">
                      <a @click="switchTab(`/ProductList/${formatUrlToLine(item.lineName)}`)" class="h f_20">
                        {{
                          item.lineName
                        }}</a>
                      <ul>
                        <li>
                          <template v-for="item1 in item.children" :key="item1">
                            <a @click="switchTab(`/productDetails/${formatUrlToLine(item1.productName)}`)" class="new">
                              {{ item1.productName }}
                              <span v-if="item1.productType == 'C'" class="icon">
                                <img src="@/assets/imgl/hot.png" alt="" />
                              </span>
                              <span v-else-if="item1.productType == 'M'" class="icon">
                                <img src="@/assets/img/new.png" alt="" />
                              </span>
                            </a>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </div>
              <div class="type">
                <div class="title">
                  {{ xlTitle[index]?.children[1]?.name }}
                </div>
                <div class="list">
                  <template v-for="item in list_p" :key="item.id">
                    <div class="box" v-if="item.id == rightProductLineId">
                      <a @click="switchTab(`/ProductList/${formatUrlToLine(item.lineName)}`)" class="h f_20">{{
                        item.lineName
                      }}</a>
                      <ul>
                        <li>
                          <template v-for="item1 in item.children" :key="item1">
                            <a @click="switchTab(`/productDetails/${formatUrlToLine(item1.productName)}`)" class="new">
                              {{ item1.productName }}
                              <span v-if="item1.productType == 'C'" class="icon">
                                <img src="@/assets/imgl/hot.png" alt="" />
                              </span>
                              <span v-else-if="item1.productType == 'M'" class="icon">
                                <img src="@/assets/img/new.png" alt="" />
                              </span>
                            </a>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="item.uniqueness == '解决方案'" class="solxl liBox">
            <div class="inner_box">
              <div class="inner">
                <div class="part ind">
                  <div class="name">
                    {{ xlTitle[index]?.children[0]?.name }}
                  </div>
                  <div class="list">
                    <template v-for="item in solutionList.industryList" :key="item.id">
                      <div v-if="item.isShow == 0" class="item">
                        <a @click="switchTab(`/industry/${formatUrlToLine(item.name)}`)" class="tit">{{
                          item.name
                        }}</a>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="part app">
                  <div class="name">
                    {{ xlTitle[index]?.children[1]?.name }}
                  </div>
                  <div class="list">
                    <template v-for="item in solutionList.applyList" :key="item.id">
                      <div v-if="item.flag != 1" class="item">
                        <a @click="switchTab(`/application/${formatUrlToLine(item.name)}`)" class="tit">{{
                          item.name
                        }}</a>
                      </div>
                    </template>
                  </div>
                  <a @click="switchTab('/case')" class="more">{{ caseBanner?.title }} <i></i></a>
                </div>
                <div class="part pl" v-if="solutionList.productApplyList?.length">
                  <div class="name">
                    {{ xlTitle[index]?.children[2]?.name }}
                  </div>
                  <div class="list">
                    <template v-for="item in solutionList.productApplyList" :key="item.id">
                      <div class="item">
                        <a @click="switchTab(`/productLevel/${formatUrlToLine(item.name)}`)" class="tit">{{
                          item.name
                        }}</a>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="himgf">
                  <div class="img himg" :style="{
                    background: `url(${formatImg(
                      plxl?.image
                    )}) no-repeat center`,
                    backgroundSize: 'cover',
                  }"></div>
                  <div class="txt" :style="{ color: `${plxl?.color}` }">
                    <div class="tit" v-html="plxl?.title"></div>
                    <div class="lcon" v-html="plxl?.subhead"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="item.uniqueness == '服务与支持'" class="xl serxl closexl liBox">
            <div class="inner">
              <div class="left">
                <a @click="switchTab('/service')" class="img himg" :style="{
                  background: `url(${formatImg(serxlimg?.image)}) no-repeat center `,
                  backgroundSize: 'cover',
                }">
                  <div class="cover"></div>
                  <div class="txt" :style="{ color: `${serxlimg?.color}` }">
                    <div class="tit" v-html="serxlimg?.title"></div>
                    <div class="lcon" v-html="serxlimg?.subhead"></div>
                  </div>
                </a>
              </div>

              <div class="right">
                <template v-for="item in xlTitle[index]?.children" :key="item.id">
                  <div class="item">
                    <a v-if="item.router != '#'" @click="switchTab(item.router)" class="tit">
                      {{ item.name }}
                    </a>
                    <div v-else class="tit">
                      {{ item.name }}
                    </div>
                    <div class="sec_list">
                      <template v-for="item1 in item.children" :key="item1.id">
                        <a @click="switchTab(item1.router)" class="sec">{{ item1.name }}</a>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div v-else-if="item.uniqueness == '关于节卡'" class="xl axl closexl liBox">
            <div class="inner">
              <div class="left">
                <a @click="switchTab('/about')" class="img himg" :style="{
                  background: `url(${formatImg(axlimg?.image)}) no-repeat center `,
                  backgroundSize: 'cover',
                }">
                  <div class="cover"></div>
                  <div class="txt" :style="{ color: `${axlimg?.color}` }">
                    <div class="tit" v-html="axlimg?.title"></div>
                    <div class="lcon" v-html="axlimg?.subhead"></div>
                  </div>
                </a>
              </div>

              <div class="right">
                <template v-for="item in xlTitle[index]?.children" :key="item.id">
                  <div class="item">
                    <a @click="switchTab(item.router)" class="tit">{{
                      item.name
                    }}</a>
                    <div class="sec_list">
                      <template v-for="item1 in item.children" :key="item1.id">
                        <a v-if="item1.router.indexOf('https://') != -1 || item1.router.indexOf('http://') != -1"
                          :href="item1.router" target="_blank" class="sec">{{
                            item1.name
                          }}</a>
                        <a v-else @click="switchTab(item1.router)" class="sec">{{
                          item1.name
                        }}</a>
                      </template>
                    </div>
                  </div>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search_box" :class="isSearch ? 'act' : ''">
      <a @click="switchTab('/')" class="logo">
        <img :src="formatImg(baseinfo?.logo)" alt="" />
      </a>
      <div class="form">
        <img class="serimg" src="@/assets/img/h_ser.png" alt="" />
        <input ref="headSearchRef" type="text" :placeholder="t('search.input')" @keyup.enter="gotoSearch('/search')"
          v-model="searchInput" />
        <img @click="toggleSearch()" class="sercha" src="@/assets/imgl/sercha.png" alt="" />
      </div>
      <div class="closeSearch" @click="toggleSearch()"></div>
    </div>
  </div>

  <MyDialog :message="t('login.isLogout')" ref="myUnLoginDialogRef" @update:confirm=confirmUnLoginClick()
    @update:cancel=cancelUnLoginClick() />

  <div class="m_header">
    <div class="top">
      <div class="headl">
        <a @click="switchTab('/')" class="logo">
          <img :src="formatImg(baseinfo?.logo)" class="comm_img" />
          <img :src="formatImg(baseinfo?.logo)" class="act_img" />
        </a>
      </div>
      <div class="headr">
        <div class="login" v-if="!userInfo.jaka" @click="switchTab('/login')">
          <img src="@/assets/imgm/login.svg" alt="" />
        </div>
        <div class="login" @click="switchTab('/login')" v-else>
          <img style="width: 24px; max-width: initial" src="@/assets/imgl/avatar.png" alt="" />
          <ul>
            <li>
              <a class="f_16" @click="switchTab('/deregister')">{{ t('login.deregister') }}</a>
            </li>
            <li><a class="f_16" @click="unLogin">{{ t('login.logout') }}</a></li>
          </ul>
        </div>
        <div @click="switchTab('/search')" class="search">
          <img class="search1" src="@/assets/imgm/search.png" alt="" />
          <img class="search2" src="@/assets/imgm/search.png" alt="" />
        </div>
        <div class="list">
          <img class="list1" src="@/assets/imgm/list.png" alt="" />
          <img class="list2" src="@/assets/imgm/list.png" alt="" />
        </div>
        <img class="cha" src="@/assets/imgm/cha.png" alt="" />
      </div>
    </div>

    <div class="m_menu" id="m_menu">
      <div class="two">
        <div class="navbar">
          <ul>
            <template v-for="(item, index) in xlTitle" :key="item.id">
              <li v-if="item.id == 79">
                <div class="first tit">
                  <a @click="switchTab(item.router)" class="first_item tit">{{ item.name }}</a>
                  <i></i>
                </div>
                <div class="sec_list">
                  <template v-for="item in list_p" :key="item.id">
                    <div class="sec_item">
                      <div class="sec">
                        <a @click="switchTab(`/ProductList/${formatUrlToLine(item.lineName)}`)" class="h f_20">{{
                          item.lineName }} </a><i></i>
                      </div>
                      <div class="third_list">
                        <template v-for="item1 in item.children" :key="item1">
                          <div @click="switchTab(`/productDetails/${item1.productName}`)" class="third_item">
                            {{ item1.productName }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
              <li v-else-if="item.id == 80">
                <div class="first tit">
                  <a href="##" class="first_item tit">{{ item.name }}</a>
                  <i></i>
                </div>
                <div class="sec_list">
                  <div class="sec_item">
                    <div class="sec">
                      <a class="h f_20">{{ item.children[0]?.name }}</a><i></i>
                    </div>
                    <div class="third_list">
                      <template v-for="item in solutionList.industryList" :key="item">
                        <div v-if="item.isShow == 0" @click="switchTab(`/industry/${item.name}`)" class="third_item">
                          {{ item.name }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="sec_item">
                    <div class="sec">
                      <a class="h f_20">{{ item.children[1]?.name }}</a><i></i>
                    </div>
                    <div class="third_list">
                      <template v-for="item in solutionList.applyList" :key="item">
                        <div v-if="item.flag != 1" @click="switchTab(`/application/${item.name}`)" class="third_item">
                          {{ item.name }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="sec_item">
                    <div class="sec">
                      <a class="h f_20">{{ item.children[2]?.name }}</a><i></i>
                    </div>
                    <div class="third_list">
                      <template v-for="item in solutionList.productApplyList" :key="item">
                        <div @click="switchTab(`/productLevel/${item.name}`)" class="third_item">
                          {{ item.name }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="sec_item">
                    <div class="sec">
                      <a @click="switchTab('/case')" class="h f_20">{{ caseBanner?.title }}</a>
                    </div>
                  </div>
                </div>
              </li>
              <li v-else>
                <div class="first tit">
                  <a @click="switchTab(item.router)" v-html="item.name"></a>
                  <i v-if="item.children"></i>
                </div>
                <div class="sec_list" v-if="item.children">
                  <template v-for="item1 in item.children" :key="item1">
                    <div class="sec_item">
                      <div class="sec">
                        <template v-if="item1.router != '#'">

                          <a class="h f_20" @click="switchTab(item1.router)" v-html="item1.name"></a><i
                            v-if="item1.children"></i>
                        </template>
                        <template v-else>

                          <a class="h f_20" v-html="item1.name"></a><i v-if="item1.children"></i>
                        </template>
                      </div>
                      <div class="third_list" v-if="item1.id == '99'">
                        <template v-for="item2 in item1.children" :key="item2">
                          <a target="_blank" :href="item2.router" class="third_item">{{ item2.name }}</a>
                        </template>
                      </div>
                      <div class="third_list" v-else>
                        <template v-for="item2 in item1.children" :key="item2">
                          <div @click="switchTab(item2.router)" class="third_item">
                            {{ item2.name }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
            </template>
          </ul>
          <div class="lbtn">{{ languageMap[locale] }}</div>
        </div>
        <div class="lang">
          <div class="back">
            <img src="@/assets/imgm/h_add.svg" alt="" />
          </div>
          <ul>
            <template v-for="item in languages" :key="item.label">
              <li :class="locale == item.lang ? 'act' : ''">
                <a :href="item.url" :target="item.is_blank ? '_blank' : ''" @click="switchLang($event, item)">{{ item.label }}</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ProductList_xl,
  selectProductLinePublishDataById,
  solutionXl,
  getPrograma,
  getBaseInfo,
  getBanner,
  deregisterValidateApi,
  tokenValidateApi,
} from "@/services/index.js";
import { ref, computed, reactive, onMounted, nextTick, watch, } from "vue";
import formatImg, { formatUrlToLine } from "@/utils/formatImg.js";
import { loginValidate, } from "@/utils/login.js";
import { arrayToMap, getUrlLang } from "@/utils/common"

import { useStore } from 'vuex';
const store = useStore()
import { useI18n } from 'vue-i18n'
import { setLang } from '@/locale'
const { t, locale } = useI18n()

import { ElMessage, ElLoading, ElMessageBox } from "element-plus";

import MyDialog from '@/components/my-dialog.vue';

// 浏览器存储
import sessionCache from "@/utils/cache";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const baseinfo = ref([]);
const plxl = ref([]);
const caseBanner = ref(null);
const axlimg = ref(null);
const serxlimg = ref(null);

let timer3
const switchTab = (path) => {
  window.routerPush(formatUrlToLine(path));
  // console.log(path);
  currPath.value = path.split("/")[1];
  // $('.closexl').stop(true, false).slideUp()
  $(".ps_xl").removeClass("act");
  $(".m_header .top").removeClass("on");
  $(".m_menu").removeClass("on");
  isSearch.value = false;
  mHeader()

  nextTick(() => {
    timer3 = setTimeout(() => {
      document.querySelector(router.currentRoute.value.hash).scrollIntoView()
    }, 100)
  })
};
const searchInput = ref("节卡机器人");
const gotoSearch = (path) => {
  // console.log(searchInput.value);
  window.routerPush(path + "?searchInfo=" + searchInput.value);
  currPath.value = path.split("/")[1];
  // $('.closexl').stop(true, false).slideUp()
  $(".ps_xl").removeClass("act");
  isSearch.value = false;
};
let headSearchRef = ref(null);
const isSearch = ref(false);
const toggleSearch = () => {
  isSearch.value = !isSearch.value;
  nextTick(() => {
    headSearchRef.value.focus();
  });
  searchInput.value = "";
};

const list_p = ref([]);
const solutionList = ref([]);

const route = useRoute();



onMounted(() => {
  // console.log(process.env)
  getlist();

  // console.log(window.location.pathname.split('/')[1]);
  currPath.value = window.location.pathname.split("/")[1];
});
const xlTitle = ref([]);
const xiala = ref([]);

const rightProductLineId = ref(null)


const getlist = () => {
  ProductList_xl(2).then((res) => {
    list_p.value = res.data;
    // console.log(res);
  });
  // 获取发布前的视觉系列id
  const lensIdMap = {
    zh: 8,
    ja: 37,
    th: '',
    ko: 71,
  }
  const lensId = lensIdMap[locale.value] || 8
  selectProductLinePublishDataById(lensId).then(res => {
    rightProductLineId.value = res.data
  })

  getBaseInfo().then((res) => {
    baseinfo.value = res.data;
  });

  solutionXl(2).then((res) => {
    console.log('solutionList', res);
    solutionList.value = res;
    mHeader();
  });

  getPrograma(1).then((res) => {
    console.log("xlTitle", res);
    xlTitle.value = res.data;
    init();
    mHeader();
    $(function () {
      $(".header").hover(
        function () {
          $(".header").addClass("hover");
        },
        function () {
          $(".header").removeClass("hover");
        }
      );

      $(".pl_navs").unbind()
      $(".pl_navs").hover(
        function () {
          // $(".pl_navs").stop().slideDown()
          getPrograma(1).then(res => {
            res.data.forEach((item, index) => {
              if (item.id == 79) {
                $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).addClass("set");
                $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("unset");
              }

            })
          })
          $(".header .con .h_left .h_nav>li").addClass("unset");
        },
        function () {
          // $(".pl_navs").stop().slideUp()
          getPrograma(1).then(res => {
            res.data.forEach((item, index) => {
              if (item.id == 79) {
                $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("set");
              }

            })
          })
          $(".header .con .h_left .h_nav>li").removeClass("unset");
        }
      );

      $(".header .con .h_left .h_nav>li").unbind()
      $(".header .con .h_left .h_nav>li").hover(function () {
        $(".ps_xl").addClass("act");
        $(".ps_inner .add-box").find(".liBox").removeClass("act");
        if (
          $(".ps_inner .add-box").eq($(this).index()).find(".liBox").length >= 1
        ) {
          $(".ps_inner").css(
            "height",
            $(".ps_inner .add-box")
              .eq($(this).index())
              .find(".liBox>div")
              .css("height")
          );
          $(".ps_inner .add-box")
            .eq($(this).index())
            .find(".liBox")
            .addClass("act");
        } else {
          $('.ps_xl').removeClass('act')
          $(".ps_inner").css("height", "0");
        }
      });

      $(".ps_xl .cover").hover(function () {
        // $(".ps_xl").stop(true, false).slideUp()
        $(".ps_xl").removeClass("act");
      });
    });
  });

  getPrograma(2).then((res) => {
    // console.log('getPrograma2', res);
    xiala.value = res.data;

  });

  getBanner({
    bannerTypeId: 2,
    status: 0,
  }).then((res) => {
    // console.log('banner', res);
    plxl.value = res.data[0];
  });
  // caseBanner
  getBanner({
    bannerTypeId: 10,
    status: 0,
  }).then((res) => {
    // console.log('banner', res);
    caseBanner.value = res.data[0];
  });
  getBanner({
    bannerTypeId: 16,
    status: 0,
  }).then((res) => {
    // console.log('banner', res);
    axlimg.value = res.data[0];
  });
  getBanner({
    bannerTypeId: 17,
    status: 0,
  }).then((res) => {
    // console.log('banner', res);
    serxlimg.value = res.data[0];
  });
};

const props = defineProps(["msg"]);

let isActive = ref(false);
let currPath = ref("/");


const plnav = ref([]);

const init = () => {
  $(function () {
    window.addEventListener("scroll", scrollEvent, true);
    $(".header .h_nav1>li").hover(
      function () {
        $(this).find(".nav2").stop(true, false).slideDown();
      },
      function () {
        $(this).find(".nav2").stop(true, false).slideUp();
      }
    );

    $(".solxl ").hover(
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 80) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).addClass("set");
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("unset");
            }

          })
        })
        $(".header .con .h_left .h_nav>li").addClass("unset");
      },
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 80) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("set");
            }

          })
        })
        $(".header .con .h_left .h_nav>li").removeClass("unset");
      }
    );
    $(".serxl ").hover(
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 81) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).addClass("set");
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("unset");
            }

          })
        })
        $(".header .con .h_left .h_nav>li").addClass("unset");
      },
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 81) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("set");
            }
          })
        })
        $(".header .con .h_left .h_nav>li").removeClass("unset");
      }
    );

    $(".header .con .h_left .h_nav>li:nth-child(4)").hover(
      function () {
        $(".ps_xl").removeClass("act");
        $(".jaxl").stop(true, false).slideDown();
      },
      function () {
        $(".jaxl").stop(true, false).slideUp();
      }
    );
    $(".jaxl .inner").hover(
      function () {
        $(".jaxl").stop(true, false).slideDown();
      },
      function () {
        $(".jaxl").stop(true, false).slideUp();
      }
    );

    $(".axl ").hover(
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 83) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).addClass("set");
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("unset");
            }
          })
        })
        $(".header .con .h_left .h_nav>li").addClass("unset");
      },
      function () {
        getPrograma(1).then(res => {
          res.data.forEach((item, index) => {
            if (item.id == 83) {
              $(`.header .con .h_left .h_nav>li:nth-child(${index + 1})`).removeClass("set");
            }
          })
        })
        $(".header .con .h_left .h_nav>li").removeClass("unset");
      }
    );

    $(".header .con .h_left .h_nav>li:nth-child(6)").hover(function () {
      $(".ps_xl").removeClass("act");
    });
    $(".header .con .h_left .h_nav>li:nth-child(7)").hover(function () {
      $(".ps_xl").removeClass("act");
    });

    $(".header .con .h_left .h_nav>li").hover(
      function () {
        $(this).siblings().addClass("unset");
      },
      function () {
        $(this).siblings().removeClass("unset");
      }
    );

    // 语言切换
    $(".header .h_right .lag").hover(
      function () {
        $(this).find("ul").stop(true, false).slideDown();
      },
      function () {
        $(this).find("ul").stop(true, false).slideUp();
      }
    );

    $(".lbtn").click(function () {
      $(".m_menu .two").addClass("on");
    });
    $(".m_menu .back").click(function () {
      $(".m_menu .two").removeClass("on");
    });
  });
};

const mHeader = () => {
  $(function () {
    // 移动端导航展开
    $(".m_header .headr .list").click(function () {
      $(".m_header ").stop(true, false).addClass("act");
      $(".m_header .m_menu").addClass("on");
      $(".m_header .top").addClass("on");
    });
    $(".m_header .headr .list2").click(function () {
      $(".m_header ").stop(true, false).addClass("act");
      $(".m_header .m_menu").addClass("on");
      $(".m_header .top").addClass("on");
    });
    $(".m_header .headr .cha").click(function () {
      $(".m_header .m_menu").removeClass("on");
      $(".m_header .top").removeClass("on");
      if ($(window).scrollTop() > 0) {
        $(".m_header ").stop(true, false).addClass("act");
        $(".m_header .top").stop(true, false).addClass("act");
      } else if ($(window).scrollTop() <= 0) {
        $(".m_header .top").stop(true, false).removeClass("act");
      }
    });
    $(".m_header .m_menu .first").unbind()
    $(".m_header .m_menu .first").on("click", function () {
      if ($(this).siblings(".sec_list").is(":hidden")) {
        $(this).addClass("on");
        $(this).siblings(".sec_list").stop().slideDown();
        $(this)
          .parent()
          .siblings("li")
          .children(".sec_list")
          .stop()
          .slideUp()
          .siblings(".tit")
          .removeClass("on");
      } else {
        $(this).removeClass("on");
        $(this).siblings(".sec_list").stop().slideUp();
      }
    });
    $(".m_header .m_menu .sec_item").unbind()
    $(".m_header .m_menu .sec_item").on("click", function () {
      if ($(this).find(".third_list").is(":hidden")) {
        $(this).addClass("on");
        $(this).siblings().removeClass("on");
        $(this).find(".third_list").stop().slideDown();
        $(this).siblings().children(".third_list").stop().slideUp();
      } else {
        $(this).removeClass("on");
        $(this).siblings().removeClass("on");
        $(this).find(".third_list").stop().slideUp();
      }
    });
  });
};

const scrollEvent = () => {
  isSearch.value = false;
  if (window.scrollY > 0) {
    isActive.value = true;
  } else {
    isActive.value = false;
  }
};

const userInfo = reactive({
  userId: null,
  userName: null,
  userAccount: null,
  userAccountEmail: null,
  jaka: null,
});
const getUserInfo = () => {
  userInfo.userId = sessionCache.getCache("jakaUserId");
  userInfo.userName = sessionCache.getCache("jakaUserName");
  userInfo.userAccount = sessionCache.getCache("jakaUserAccount");
  userInfo.userAccountEmail = sessionCache.getCache("jakaUserAccountEmail");
  userInfo.jaka = sessionCache.getCache("jakaUserInfo") || null

  if (userInfo.jaka) {
    loginValidate(userInfo.jaka.token).then(res => {
      console.log('验证', res);
      if (res) {
        if (!res.success) {
          console.log('用户不存在正在清除数据');
          sessionCache.deleteCache("jakaUserId");
          sessionCache.deleteCache("jakaUserName");
          sessionCache.deleteCache("jakaUserAccount");
          sessionCache.deleteCache("jakaWhereFrom");
          sessionCache.deleteCache("jakaUserInfo");
          userInfo.userId = null;
          userInfo.userName = null;
          userInfo.userAccount = null;
          userInfo.jaka = null;
          // console.log(router.currentRoute.value.path);
        }
      }
    })
  }
  const currPath = router.currentRoute.value.path;
  if (currPath == "/deregister" && !sessionCache.getCache('jakaUserInfo')) {
    window.routerPush("/");
  }

  // console.log('userInfo', userInfo);
  $(function () {
    // 退出登录
    $(".header .h_right .user_info").unbind();
    $(".header .h_right .user_info").hover(
      function () {
        $(this).find("ul").stop(true, false).slideDown();
      },
      function () {
        $(this).find("ul").stop(true, false).slideUp();
      }
    );

    $(".m_header .headr .login").hover(
      function () {
        $(".m_header .headr .login ul").stop(true, false).slideDown();
      },
      function () {
        $(".m_header .headr .login ul").stop(true, false).slideUp();
      }
    );
  });


};

const languages = ref([
  {
    label: "简体中文",
    lang: 'zh',
    url: "",
    is_blank: false,
  },
  {
    label: "English",
    lang: 'en',
    url: "https://www.jakarobotics.com/",
    is_blank: true,
  },
  {
    label: "日本語",
    lang: 'jp',
    url: "jp",
    is_blank: false,
    // url: "https://www.jakarobotics.com/ja/",
  },
  {
    label: "Español",
    lang: 'es',
    url: "https://www.jakarobotics.com/es/",
    is_blank: true,
  },
  {
    label: "Deutsch",
    lang: 'de',
    url: "https://www.jakarobotics.com/de/",
    is_blank: true,
  },
  {
    label: "Français",
    lang: 'fr',
    url: "https://www.jakarobotics.com/fr/",
    is_blank: true,
  },
  {
    label: "русский",
    lang: 'ru',
    url: "https://www.jakarobotics.com/ru/",
    is_blank: true,
  },
  // {
  //   label: "ไทย",
  //   lang: 'th',
  //   url: "th",
  //   is_blank: false,
  // },
  {
    label: "한국어",
    lang: 'ko',
    url: "ko",
    is_blank: false,
  },
]);
const languageMap = arrayToMap(languages.value, 'lang', 'label')
languageMap.ja = "日本語"
const currentLang = ref(sessionCache.getCache('lang') || 'zh');
const switchLang = (e, item) => {
  if (!item.is_blank) {
    // setLang(item.lang)
    // currentLang.value = item.lang
    const urlLang = getUrlLang(window.location.href)
    let target = window.location.href
    if (urlLang) {
      target = item.url ? target.replace(`/${urlLang}/`, `/${item.url}/`) : target.replace(`/${urlLang}/`, '/')
    } else {
      target = `/${item.url}` + window.location.pathname
    }
    console.log('switchLang', target)
    e.preventDefault();
    window.location.href = target
  }
}


const confirmUnLoginClick = ref(null)
const cancelUnLoginClick = ref(null)


const myUnLoginDialogRef = ref(null)
const unLogin = () => {
  // console.log(myDialogRef.value);
  myUnLoginDialogRef.value.openDialog()
  confirmUnLoginClick.value = () => {
    ElMessage({
      type: "success",
      message: t('login.successLogout'),
    });
    sessionCache.deleteCache("jakaUserId");
    sessionCache.deleteCache("jakaUserName");
    sessionCache.deleteCache('jakaUserAccount')
    sessionCache.deleteCache("jakaWhereFrom");
    sessionCache.deleteCache('jakaUserInfo')
    userInfo.userId = null;
    userInfo.userName = null;
    userInfo.userAccount = null;
    userInfo.jaka = null;
    // console.log(router.currentRoute.value.path);

    const currPath = router.currentRoute.value.path;
    if (currPath == "/deregister" && !sessionCache.getCache('jakaUserInfo')) {
      window.routerPush("/");
    }
  }
  cancelUnLoginClick.value = () => {
    ElMessage({
      type: "info",
      message: t('login.cancelLogout'),
    });
  }
};

watch(
  () => router.currentRoute.value,
  (newValue) => {
    // console.log(newValue.query.wxtoken);
    if (newValue.query.wxtoken) {
      const isMiniProgram = window.navigator.userAgent.toLocaleLowerCase().indexOf('miniprogram') > -1;
      // const isMiniProgram = true
      if (isMiniProgram) {
        sessionCache.setCache('jakaUserInfo', { token: newValue.query.wxtoken })
      }
    }
    getUserInfo();

    if (newValue.hash) {
      $(".ps_xl").removeClass("act");
    }
    getPrograma(1).then(res => {
      console.log(res);
      console.log(router.currentRoute.value.name);
      let currRouterIndex = null
      res.data.forEach((item, index) => { item.router.indexOf(router.currentRoute.value.name) != -1 ? currRouterIndex = index : '' })
      if (currRouterIndex != null) {
        $('.header .con .h_left .h_nav>li .a').removeClass('act')
        $(`.header .con .h_left .h_nav>li:nth-child(${currRouterIndex + 1}) .a`).addClass('act')
      } else {
        res.data.forEach((item, index) => {
          item.children?.forEach((item2, index2) => {
            if (item2.router.indexOf(router.currentRoute.value.name) != -1) {
              currRouterIndex = index
            }
          })
        })
        if (currRouterIndex != null) {
          $('.header .con .h_left .h_nav>li .a').removeClass('act')
          $(`.header .con .h_left .h_nav>li:nth-child(${currRouterIndex + 1}) .a`).addClass('act')
        } else {
          res.data.forEach((item, index) => {
            item.children?.forEach((item2, index2) => {
              item2.children?.forEach((item3, index3) => {
                if (item3.router.indexOf(router.currentRoute.value.name) != -1) {
                  currRouterIndex = index
                }
              })
              $('.header .con .h_left .h_nav>li .a').removeClass('act')
              if (currRouterIndex != null) {
                console.log(currRouterIndex);
                $(`.header .con .h_left .h_nav>li:nth-child(${currRouterIndex + 1}) .a`).addClass('act')
              }

            })
          })
        }
      }
      console.log('currRouterIndex', currRouterIndex);
    })
  },
  { immediate: true }
);
</script>